.sidePanel {
    height: 92.5vh; /* Specify a height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    right: -20px;
    background-color: #ffffff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-left: 20px;
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
  }