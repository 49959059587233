.news-page {
    padding: 10px;
    max-width: 120%;
    margin: 0 auto;
}

.section-title {
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    border-bottom: none;
}

.news-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.news-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 300px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.news-item:hover {
    transform: translateY(-5px);
}

.news-image {
    height: 180px;
  }

.news-text {
    padding: 15px;
    text-align: center;
}

.news-text h3 {
    color: #555;
    margin-top: -5px;
}

.news-text h2 {
    border-bottom: 2px solid #e6aa2a;
    color: #333;
    margin: -5px 0;
    font-size: 1.2em;
}

.news-link{
    color: #53aa48;
    font-size: 0.9em;
    margin-top: 15px;
}

.news-link:hover {
    color: #448a3b;
}