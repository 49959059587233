body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;

    background-color: white;
}

.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section-title {
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 2px solid #e6aa2a;
}

.section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
  color: white;
  margin: 0px 0 50px 0;
}

.gradient{
    background-image: linear-gradient(to top, black, transparent);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: 0;
}

.outer-wrapper {
    width: 100vh;
    height: 100vw;
    transform: rotate(-90deg) translateX(-100vh);
    transform-origin: top left;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    scrollbar-width: none;
}

.text-over1 {
    position: absolute;
    top: 60%;
    left: 5%;
    color: white;
    font-size: 10vh;
    font-weight: bold;
    transition: 0.2s;
    /* fontWeight: bold;
    textAlign: center;
    backgroundColor: rgba(0, 0, 0, 0.5);
    borderRadius: 5px; */
}

.text-over2 {
    position: absolute;
    top: 85%;
    left: 5.3%;
    color: white;
    font-size: 3vh;
}

