.header{
    position: fixed;
    width: 100%;
    transition: 0.3s;
    z-index: 1000;
    font-family: 'Poppins' , sans-serif ;
    font-weight: bold;
}

.main-nav-container {
    width: 100%;
    border-bottom: none;
}

.header-gradient {
    position: relative; 
    height: 5px;
    background: linear-gradient(to right, #ff8829, #a5c90f, #6f9c3d);
    margin: 0; 
    border: none;
}

.container {
    display: flex;
    height: 4em;
    background: white;
}

.SElogo-container {
    padding: 0.4em 2em;
}

.navs-container {
    margin: 0;
    display: flex;            
    justify-content: center;   
    align-items: center;       
    height: 100%;             
}

.navs-button {
    padding: 0 1em;
    height: 100%;             
    display: flex;             
    align-items: center;
}

a {
    text-decoration: none;
    color: black;
}

.navs-button:hover {
    border-bottom: 5px solid #ff8829;
    border-radius: 5px;
    transition: 0.2s;
}

.welcome-text {
    position: relative;
    margin-left: auto;
    margin-right: 0px;
    display: flex;             
    align-items: center;
    text-align: right;
}

.profile-container {
    position: relative;
    margin-left: auto;
    margin-right: 3em;
    display: flex;            
    justify-content: center;   
    align-items: center;
}

.profile-container img {
    height: 40px;
    border-radius: 80%;
    cursor: pointer;
}

.dropdown-profile {
    display: none;
    position: absolute;
    top: 100%;               
    right: 0;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
}

.profile-container div:hover .dropdown-profile {
    display: block;
}

.dropdown-profile a {
    color: black;
    text-decoration: none;
    display: block;
    padding: 8px 50px;
    white-space: nowrap;
}

.dropdown-profile a:hover {
    background-color: #ddd;

}