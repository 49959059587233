.program-page-container {
  width: 100%;
  padding-top: 10vh; /* Adjusted to vh */
  /* height: 100%; */
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
  gap: 5vh; /* Adjusted to vh */
  max-width: 80%;
  /* padding-left: 25%; */
  padding: 3%;
  /* padding-bottom: 10%; */
  margin: auto;
  margin-bottom: 5%;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1vh; /* Adjusted to vh */
  box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.1); /* Adjusted to vh */
  background: #fff;
  overflow: hidden;
  transition: transform 0.3s ease;
  height: 45vh; /* Set a fixed height using vh */
  /* width: 35vh; */

}

.card-container:hover {
  transform: translateY(-1vh); /* Adjusted to vh */
}

.card-image img {
  width: 100%;
  height: 22vh; /* Adjusted to vh */
  object-fit: cover;
}

.card-content {
  padding: 3vh 1.5vh 1.5vh 1.5vh; /* Adjusted to vh */
}

.card-content h3 {
  font-size: 1.8vh; /* Adjusted to vh */
  margin: 0;
  color: #333;
}

.card-link {
  display: inline-block;
  margin-top: 3vh; 
  color: #53aa48;
  text-decoration: none;
  font-weight: 500;
}

.card-link:hover {
  color: #448a3b;
}

.card-link .arrow {
  float: inline-end;
  margin-top: -15px;
  margin-left: 1vh;
  font-size: 36px;
}

/* Media Query for Tablet Devices */
@media screen and (max-width: 1024px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    gap: 3vh; /* Adjusted to vh */
  }
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr; /* Single column layout on mobile */
    gap: 2vh; /* Adjusted to vh */
  }

  .card-container {
    margin-bottom: 2vh; /* Adjusted to vh */
  }
}

.program-title{
  text-align: center;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: 600;
    color: #423224;
    margin-bottom: 20px;
    border-bottom:none;
    padding-bottom: 10px;
}
